export const COLOR_STATUS = {
  ZERO_MISTAKES: 'rgb(128, 216, 255)',
  HAS_PASSED: 'rgb(174, 213, 129)',
  HAS_NOT_CAME: 'rgb(255, 238, 88)',
  NOT_HAS_PASSED: 'rgb(174, 213, 129)',
  IGNORE_RESUTLS: '#6F5686'
};

export const COLOR_STATUS_NEW = {
  ZERO_MISTAKES: '#95D6FB',
  HAS_PASSED: '#B6D38A',
  HAS_NOT_CAME: '#FCEF71',
  NOT_HAS_PASSED: '#B6D38A',
  IGNORE_RESUTLS: '#6F5686',
  UNSEEN: '#CBCCCC'
};

export const RESULT_TYPES = {
  AFWEZIG: 'Afwezig',
  VOLDOENDE: 'Voldoende',
  ONVOLDOENDE: 'Onvoldoende'
};

export const RESERVED_COLORS = {
  IS_MIJN_RESERVATION: '#f3ee41',
  WAS_EXCHANGED: '#fb8c00',
  DIFFERENT_EXAM_DATE: '#90efa0'
};

export const CBR_TYPES = {
  TOP: 'TOP',
  MIJN: 'MIJN'
};

export const PURPLE = '#8A0E67';

export const RESULT_FILTERS = 'result_filters';
