<template lang="pug">
  tr(:key="`${item.id}-row`" :active="isSelected" :class="{'updated' : item.animation}" class="ra-row")
    td.ra-index.pl-1(@click="linkCandidate(item.candidate.id)")
      span.pr-1 {{ positionInList }}
      ico-arrow-link
    td.ra-reserved-info {{ item.location || '-' }}
      div.white-space-normal(v-if="item.datetime") {{ getItemDateTime || '-' }}
    td.ra-name {{ item.candidate.name }}
    td.ra-appID {{ item.application_id || '-' }}
    td.ra-cdn {{ item.candidate.number || '-' }}
    td.ra-dob {{ item.candidate.date_of_birth | formattedDate }}
      div.white-space-normal {{ item.candidate.phone }}
    td.ra-is-reapplied
      .d-flex.justify-center
        app-checkbox(
          :inputValue="item.task_is_reapplied"
          :id="`task_is_reapplied-${item.id}`"
          disabled
        )
    td.ra-results(:style="{backgroundColor: colorStatus}")
      result-service-provider
        div.text-center
          result-info(v-if="!isMijnReservation" :itemID="item.id" :result="item.result" :isMijnReservation="isMijnReservation" :candidateName="item.candidate.name")
          mijn-info(v-if="isMijnReservation" :itemID="item.id" :reservedExam="item.result" :candidateName="item.candidate.name")
    td.ra-status
      base-chip(v-if="item.result" :color="statusChipColor" :value="item.result.status" dark)
      base-chip(v-else value="Not fetched" dark)
    td.ra-send-results
      result-email(:itemID="item.id" :value="item.send_result_emails").justify-center
    td.ra-course-type {{ item.course_type || '-' }}
    td.ra-product-type {{ item.product_name || '-' }}
    td.ra-email
      failed-email(:itemID="item.id" :disabled="!hasResult")
    td.ra-due-date
      due-date(:item="item")
    td.ra-state
      span(v-if="item && item.task_status && (item.task_status !== 'OPEN')") {{ REAPPLY_APPLICATIONS_TYPES_LIST[item.task_status].label }}
      span(v-else-if="item && item.task_status && (item.task_status === 'OPEN')") Open
      span(v-else) -
    td.ra-notes
      slot(name="log")
        task-notes(
          :ID="item.id"
          :appID="item.application_id"
          :logs="item.task_logs"
          @change:log="updateLogs"
          @change:status="$emit('needToUpdate')"
        )
</template>

<script>
import { convertToDefaultDateHourFormat } from '@/util'
import { REAPPLY_APPLICATIONS_TYPES_LIST, REAPPLY_APPLICATIONS_TAB } from '../core/reapplyApplications-const'
import { RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam'
import { COLOR_STATUS } from '@/app/admin/modules/results/core/results-const'

export default {
  props: {
    isSelected: Boolean,
    select: Function,
    item: {type: Object, required: true},
    index: Number,
    pagination: Object,
    primaryPagination: Object,
    moreThanOnePageVisible: Boolean,
    activeItems: Array,
    loading: Boolean
  },

  inject: {
    updateInList: 'updateItem',
    getDefaultPageSize: 'getDefaultPageSize',
    reapplyService: 'reapplyService'
  },

  data: () => ({
    REAPPLY_APPLICATIONS_TYPES_LIST,
    REAPPLY_APPLICATIONS_TAB
  }),

  computed: {
    getItemDateTime() {
      return convertToDefaultDateHourFormat(this.item.datetime)
    },

    isMijnReservation() {
      return !!(this.item && this.item.is_mijn_reservation)
    },

    statusChipColor() {
      if (!this.item.result) return '#000000'
      if (this.item.result.status === RESULT_STATUS.LOADED) return '#52d18b'
      if (this.item.result.status === RESULT_STATUS.FAILED) return '#ff5252'
      if (this.item.result.status === RESULT_STATUS.FETCHING) return '#fb8c00'
      return '#000000'
    },

    colorStatus() {
      if (!this.item.result) return null
      if (this.item.result && this.item.result.ignore_results) return COLOR_STATUS.IGNORE_RESUTLS
      if (this.item.result && this.item.result.has_zero_mistakes) return COLOR_STATUS.ZERO_MISTAKES
      if (this.item.result && this.item.result.has_passed) return COLOR_STATUS.HAS_PASSED
      if (this.item.result && this.item.result.has_not_came) return COLOR_STATUS.HAS_NOT_CAME
      if (!this.loadedSeen) return null
      if (this.item.result && !this.item.result.has_passed) return COLOR_STATUS.NOT_HAS_PASSED
      return null
    },

    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size
    },

    hasResult() {
      return !!(this.item && this.item.result && this.item.result.results === "Onvoldoende")
    }
  },

  methods: {
    async linkCandidate(candidateID) {
      let routeData = this.$router.resolve({name: this.$ROUTER_NAMES.CANDIDATES_ALL, query: {id: candidateID || undefined}})
      window.open(routeData.href, '_blank');
    },

    updateNotes(val) {
      this.item.notes = val
    },

    updateLogs({res, task_due_date}) {
      this.updateInList(this.item.id, 'task_logs', [res, ...this.item.task_logs])
      if (task_due_date) this.updateInList(this.item.id, 'task_due_date', task_due_date)
    }
  },

  components: {
    resultServiceProvider: () => import('@/app/admin/modules/results/components/ResultsServiceProvider.vue'),
    icoArrowLink: () => import('@/assets/img/ui/crm/IcoArrowLink.vue'),
    baseChip: () => import('@/components/global/BaseChip.vue'),
    resultEmail: () => import('./actions/ResultEmail.vue'),
    resultInfo: () => import ('./actions/ExamInfo.vue'),
    mijnInfo: () => import ('./actions/MijnExamInfo.vue'),
    dueDate: () => import('./ReapplyApplicationDueDate.vue'),
    taskNotes: () => import('./ReapplyApplicationNotes.vue'),
    failedEmail: () => import('./actions/FailedEmail.vue'),
    appCheckbox: () => import('@/components/global/Checkbox.vue')

  }
}
</script>

<style lang="scss" scoped>
.contents:nth-child(2n) tr {
  background: #F4F7F9;
}

.ra-row {
  .ra- {
    &index {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .25s ease-in-out;

      &:hover {
        svg {
          transform: rotate(45deg) scale(1.2);
          transition: all .25s ease-in-out;
        }
      }
    }
    &is-reapplied {
      max-width: 80px;
      width: 80px;
    }

    &reserved-info {
      max-width: 180px;
      width: 180px;
    }
    
    &notes {
      max-width: 140px;
    }
  }
}

</style>

<style lang="scss">
.ra-index {
  padding-left: 4px !important;
}
</style>
